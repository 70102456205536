import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AppointmentRecordingFragment,
  GetAppointmentRecordingQuery,
  GetAppointmentRecordingQueryService,
  GetAppointmentRecordingQueryVariables,
} from '../graphql/appointment-recording.onelife.generated';
import { map, Observable, share, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { QueryRef } from 'apollo-angular';
import { differenceInDays } from 'date-fns';
import { startWith } from 'rxjs/operators';

interface HealthScribeBannerContent {
  iconClass: Record<string, boolean>;
  bannerText: string;
  buttonClass: 'flat' | 'primary';
  link: string;
}

@Component({
  selector: 'omg-healthscribe-banner',
  templateUrl: './healthscribe-banner.component.html',
  styleUrls: ['./healthscribe-banner.component.scss'],
})
export class HealthscribeBannerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() appointmentId: number;

  bannerContent$: Observable<HealthScribeBannerContent | null>;
  bannerVisible$: Observable<boolean>;

  private readonly pollIntervalMs: number = 5000;
  // Keep track of polling state so the polling state isn't constantly reset
  private isPolling: boolean = false;
  private appointmentRecordingQueryRef:
    | QueryRef<
        GetAppointmentRecordingQuery,
        GetAppointmentRecordingQueryVariables
      >
    | undefined;

  get defaultBannerContent(): HealthScribeBannerContent {
    return {
      iconClass: { 'icon-microphone': true },
      bannerText: 'Use HealthScribe to record and summarize visit',
      buttonClass: 'primary',
      link: `${environment.adminApp.host}/scribe/for-appointment?appointmentId=${this.appointmentId}`,
    };
  }

  constructor(
    private getAppointmentRecordingQueryService: GetAppointmentRecordingQueryService,
  ) {}

  ngOnInit(): void {
    this.initializeQueryRef();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appointmentId) {
      if (this.appointmentRecordingQueryRef) {
        this.appointmentRecordingQueryRef.refetch({
          id: this.appointmentId.toString(),
        });
      } else {
        this.initializeQueryRef();
      }
    }
  }

  ngOnDestroy(): void {
    this.stopPollingForRecording();
  }

  onClick(link: string): void {
    link && window.open(link, '_healthscribe');
  }

  private initializeQueryRef() {
    this.appointmentRecordingQueryRef =
      this.getAppointmentRecordingQueryService.watch({
        id: this.appointmentId.toString(),
      });
    const sharedResult =
      this.appointmentRecordingQueryRef.valueChanges.pipe(share());
    this.bannerContent$ = sharedResult.pipe(
      tap(result => this.setPolling(result.data.appointment?.recording)),
      map(result =>
        this.mapToBannerContent(result.data.appointment?.recording),
      ),
    );
    this.bannerVisible$ = sharedResult.pipe(
      map(result => {
        if (!result.data.appointment?.recording) return true;

        const date = result.data.appointment.recording.createdAt;
        const difference = differenceInDays(new Date(), new Date(date));
        return difference <= 7;
      }),
      startWith(true),
    );
  }

  private mapToBannerContent(
    recording?: AppointmentRecordingFragment | null,
  ): HealthScribeBannerContent {
    if (recording) {
      switch (recording.state) {
        case 'ready_to_record':
          return {
            ...this.defaultBannerContent,
            link: this.recordingLink(recording.id),
          };
        case 'recording':
        case 'transcribing':
        case 'archived':
        case 'transcribing_failed':
        case 'transcribed':
          return {
            iconClass: { 'icon-magic': true },
            bannerText: 'View visit summary',
            buttonClass: 'flat',
            link: this.recordingLink(recording.id),
          };
      }
    }
    return this.defaultBannerContent;
  }

  private recordingLink(recordingId: string): string {
    return `${environment.adminApp.host}/scribe/record/${recordingId}`;
  }

  private setPolling(recording?: AppointmentRecordingFragment | null): void {
    if (
      !recording ||
      ['ready_to_record', 'recording', 'transcribing'].includes(
        recording?.state,
      )
    ) {
      this.startPollingForRecording();
    } else {
      this.stopPollingForRecording();
    }
  }

  private startPollingForRecording(): void {
    if (!this.isPolling) {
      this.isPolling = true;
      this.appointmentRecordingQueryRef?.startPolling(this.pollIntervalMs);
    }
  }

  private stopPollingForRecording(): void {
    if (this.isPolling) {
      this.appointmentRecordingQueryRef?.stopPolling();
      this.isPolling = false;
    }
  }
}
